var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-4"
  }, [_c('i', {
    staticClass: "fa fa-backward"
  }), _c('router-link', {
    attrs: {
      "to": {
        name: _vm.UserListPath.name
      }
    }
  }, [_vm._v(" user ")])], 1)], 1), _c('b-card', {
    attrs: {
      "header": "Edit User",
      "header-bg-variant": "primary",
      "header-tag": "h5",
      "header-class": "font-weight-bold"
    }
  }, [_c('b-form', {
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Username"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.username,
      callback: function ($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "email",
      "required": ""
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "User Role"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "currency",
      "options": _vm.roleOptions,
      "required": ""
    },
    on: {
      "input": _vm.onSelectRole
    },
    model: {
      value: _vm.roleSelected,
      callback: function ($$v) {
        _vm.roleSelected = $$v;
      },
      expression: "roleSelected"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.form.is_active,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_active", $$v);
      },
      expression: "form.is_active"
    }
  }, [_vm._v("Active")])], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.resetPassword,
      callback: function ($$v) {
        _vm.resetPassword = $$v;
      },
      expression: "resetPassword"
    }
  }, [_vm._v("Reset Password")])], 1)], 1)], 1), _vm.resetPassword ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Password"
    }
  }, [_c('b-form-input', {
    attrs: {
      "state": !(_vm.form.password.length < 8),
      "min": "8",
      "type": "password"
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _vm.form.password.length < 8 ? _c('small', {
    staticClass: "text-danger"
  }, [_vm._v("minimum 8 character")]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Confirm Password"
    }
  }, [_c('b-form-input', {
    attrs: {
      "state": _vm.form.password == _vm.form.confirmPassword && _vm.form.confirmPassword != '',
      "type": "password"
    },
    model: {
      value: _vm.form.confirmPassword,
      callback: function ($$v) {
        _vm.$set(_vm.form, "confirmPassword", $$v);
      },
      expression: "form.confirmPassword"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v("Submit")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }